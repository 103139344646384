/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
require('jquery');

require('animate.css');

require('aos/dist/aos.css');
require('aos');

require('font-awesome/css/font-awesome.css');

require("lity/dist/lity.css");
require("lity");

require('materialize-css/dist/css/materialize.min.css');
require('materialize-css');

require('owl.carousel/dist/assets/owl.carousel.min.css');
require('owl.carousel');

require('waypoints/lib/jquery.waypoints');
require('jquery.counterup');

require('jquery-match-height');

require('spectragram');

require('scrollit');

// require('slick-carousel/slick/slick');
// require('slick-carousel/slick/slick.css');
// require('slick-carousel/slick/slick-theme.css');

// // Our Min CSS
require('./styles/app.scss');
require('./css/color/style1.css');